import styled from 'styled-components';

export const TrackContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: calc(50% - 1rem);
  flex-direction: column;
  aspect-ratio: 1;
  position: relative;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 1)), var(--track-image-url);
  background-size: cover;
`;

export const SongContainer = styled.div`
  text-align: center;
  position: absolute;
  bottom: 20px;
`;

export const TitleText = styled.p`
  color: #fff;
  font-weight: bold;
  margin:0;
`;

export const ArtistText = styled.p`
  color: #f5f5f5;
  margin:0;
  font-size: 12px;
`;
