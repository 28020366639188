import React from 'react';

import * as styles from './styles/TrackSearchResults.styles';

export default function Track({ track, chooseTrack }) {
  function handlePlay() {
    chooseTrack(track);
  }
  return (
    <styles.TrackContainer onClick={handlePlay} style={{'--track-image-url': 'url('+largestAlbumImage(track.album.images).url+')'}}>
      <styles.SongContainer>
        <styles.TitleText>{track.name}</styles.TitleText>
        <styles.ArtistText>{track.artists[0].name}</styles.ArtistText>
      </styles.SongContainer>
    </styles.TrackContainer>
  );
};


export function largestAlbumImage(images) {
  return images.reduce((largest, image) => {
    if (image.height > largest.height) return image;
    return largest;
  }, images[0]);
}