import React, { useState, useEffect, useMemo } from 'react';

import useAuth from './hooks/useAuth';
import Player from './Player';
import TrackSearchResult, { largestAlbumImage } from './TrackSearchResult';
import SpotifyWebApi from 'spotify-web-api-node';
import axios from 'axios';
import {
  DashBoardContainer,
  SearchInput,
  ResultsContainer,
  LyricsContainer,
  PlayerContainer,
  TabFolder,
  Tab,
} from './styles/Dashboard.styles';

import {
  TrackContainer,
  SongContainer,
  TitleText,
  ArtistText,
} from './styles/TrackSearchResults.styles';

const spotifyApi = new SpotifyWebApi({
  clientId: process.env.REACT_APP_CLIENT_ID,
});

const Dashboard = ({ code, deviceName }) => {
  const accessToken = useAuth(code);
  const [playingTrack, setPlayingTrack] = useState();
  const [tracks, setTracks] = useState();
  const [hierarchy, setHierarchy] = useState();
  const [view, setView] = useState();

  console.log({view});

  function chooseTrack(track) {
    setPlayingTrack(track);
  }

  useEffect(() => {
    if (!accessToken) return;
    spotifyApi.setAccessToken(accessToken);
  }, [accessToken]);

  useEffect(() => {
    if (!accessToken) return;
    (async () => {
      const LIMIT=50;
      let playlists = [];
      do {
        const {body} = await spotifyApi.getUserPlaylists({limit: LIMIT, offset: playlists.length});
        var got = body.items.length;
        console.log(body)
        playlists.push(...body.items);
      } while (got == LIMIT);
      
      playlists = playlists.filter(playlist => playlist.name.startsWith('Ellis'));
      playlists = (await Promise.all( playlists.map(p => spotifyApi.getPlaylist(p.id)) )).map(p => p.body);
      console.log({playlists})
      const hierarchy = parsePlaylists(playlists);
      console.log({hierarchy})

      setHierarchy(hierarchy.children['Ellis']);
    })();
  }, [accessToken])


  useEffect(() => {
    if (view) return;
    if (!hierarchy) return;
    if (hierarchy.tracks) {
      setView(hierarchy);
    } else {
      setView(hierarchy.children[Object.keys(hierarchy.children)[0]]);
    }
  }, [hierarchy, view]);

  const playingTrackUris = useMemo(() => 
    Array.isArray(playingTrack) 
      ? playingTrack.map(t=>t.uri) 
      : playingTrack?.uri, 
    [playingTrack]);

  return (
    <DashBoardContainer>
      {/*hierarchy && <TabFolder>
        {hierarchy.tracks && <Tab onClick={() => setView(hierarchy)} className={view === hierarchy?'active':''}>
          Ellis
        </Tab>}
        {Object.keys(hierarchy.children).map(folder => <Tab className={view === hierarchy.children[folder] ? 'active':''} onClick={() => setView(hierarchy.children[folder])}>
          {folder}
        </Tab>)}
  </TabFolder>*/}

      {view != hierarchy && <a style={{color:"white", fontSize: 20}} onClick={() => setView(view.parent)}>&laquo; Tillbaka</a>}
      <h1 style={{color:"white", fontSize: 15, textAlign:"center"}}>{view && view.name}</h1>
      {view && <ResultsContainer>
        {view.children && Object.entries(view.children).map(([name,childview]) => <>
          {Object.entries(childview.children).length>0 && <TrackContainer onClick={() => setView( childview )} >
            <SongContainer>
              <img src="https://st4.depositphotos.com/10376142/28210/v/450/depositphotos_282109328-stock-illustration-gold-headphone-and-sound-waves.jpg" style={{width: '60%'}} />
              <TitleText>{name}</TitleText>
              <ArtistText>(mapp)</ArtistText>
            </SongContainer>
          </TrackContainer>}
        </>)}

        {view.children && Object.entries(view.children).map(([name,childview]) => <>
          {childview.tracks && Object.entries(childview.children).length==0 && <TrackContainer onClick={() => setPlayingTrack( childview.tracks )} style={{'--track-image-url': 'url('+childview.image?.url+')'}}>
            <SongContainer>
              <TitleText>{name}</TitleText>
              <ArtistText>{childview.tracks.length} delar</ArtistText>
            </SongContainer>
          </TrackContainer>}
        </>)}
        {view.tracks && view.tracks.map(track => <>
          <TrackContainer onClick={()=>chooseTrack(track)} style={{'--track-image-url': 'url('+largestAlbumImage(track.album.images).url+')'}}>
            <SongContainer>
              <TitleText>{track.name}</TitleText>
              <ArtistText>{track.artists[0].name}</ArtistText>
            </SongContainer>
          </TrackContainer>
          {/* 
          <TrackSearchResult
              track={track}
              key={track.id}
              chooseTrack={chooseTrack}
            />*/}
        </>)}
      </ResultsContainer>}

	  <div>
	  

	  </div>
      <PlayerContainer>
        <Player deviceName={deviceName} accessToken={accessToken} trackUri={playingTrackUris} />
      </PlayerContainer>
    </DashBoardContainer>
  );
};

export default Dashboard;




function parsePlaylists(playlists) {
  const hierarchy = {children:{}};
  for (const playlist of playlists) {
    const path = playlist.name.split('/');
    let current = hierarchy;
    for (let folder of path) {
      folder = folder.trim();
      if (!current.children[folder]) {
        current.children[folder] = {children: {}, name: folder, parent: current};
      }
      current = current.children[folder];
    }
    current.tracks = playlist.tracks.items.map(t  => t.track);
    current.image = playlist.images[0];
    
  }
  return hierarchy;
}